import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"
import wemail from "../images/white_mail.svg"
import alarm_dark from "../images/alarm_dark.svg"
import check_circle from "../images/check_circle.svg"
import minus_circle from "../images/minus_circle.svg"

import openGpaphImage from "../images/opengraph/pricing.jpg"



function Pricing () {


  return (
    <Layout>

    <section className="pricing">
      <div className="container">
        <div className="row">

            <div className="col-xl-4 offset-xl-4 col-lg-12 col-md-12 col-12 text-center">
                <h1>Simple pricing  for any team size</h1>
            </div>

            <div className="col-xl-8 offset-xl-2 col-lg-12 col-md-12 col-12">
                <div className="row items-wrapper">



                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="pricing-item shadow">
                            <div className="header">
                                <h4 className="text-center">Pro</h4>
                                <span className="price text-center">$29</span>
                                <Link to='/contact-us' className="btn btn-bright text-center">30-days free trial</Link>
                                <div className="small-desc-wrapper">
                                    <hr />
                                    <span className="small-desc">
                                        <span>2,000 </span> 
                                        Active network peers incl 
                                        <div className="tooltip">
                                            <img src={alarm_dark} alt='alarm_icon' />
                                            <span className="tooltiptext shadow">
                                                    $0.029 (0 - 100,000)<br/>
                                                    $0.024 (100,001 - 1,000,000)<br/>
                                                    $0.019 (From 1,000,001)
                                            </span>
                                        </div>
                                        
                                    </span>
                                    <span className="small-desc">
                                        <span>300,000 </span> 
                                        Authorization Checks  
                                        <div className="tooltip">
                                            <img src={alarm_dark} alt='alarm_icon' />
                                            <span className="tooltiptext shadow">
                                                $0.04 / 1000 (0 - 10,000,000)<br/>
                                                $0.03 / 1000 (From 10,000,001)
                                            </span>
                                        </div>
                                    </span>
                                    <hr />
                                </div>
                            </div>
                            <div className="options">

                                <div className="row">
                                    <div className="col-1">
                                        <img src={check_circle} alt='ckeck_icon' />
                                    </div>
                                    <div className="col-11">
                                        <p>Identity and authentication</p>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-1">
                                            <img src={check_circle} alt='ckeck_icon' />
                                        </div>
                                        <div className="col-11">
                                            <p>Service integration SDKs</p>
                                        </div>
                                    </div>
                                <div className="row">
                                    <div className="col-1">
                                            <img src={minus_circle} alt='ckeck_icon' />
                                        </div>
                                        <div className="col-11">
                                            <p>UI integration SDK</p>
                                        </div>
                                    </div>
                                <div className="row">
                                    <div className="col-1">
                                            <img src={check_circle} alt='ckeck_icon' />
                                        </div>
                                        <div className="col-11">
                                            <p>Audit</p>
                                        </div>
                                    </div>
                                <div className="row">
                                    <div className="col-1">
                                            <img src={check_circle} alt='ckeck_icon' />
                                        </div>
                                        <div className="col-11">
                                            <p>Authorization</p>
                                        </div>
                                    </div>
                                <div className="row">
                                    <div className="col-1">
                                        <img src={check_circle} alt='ckeck_icon' />
                                    </div>
                                    <div className="col-11">
                                        <p>Community forum</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-1">
                                        <img src={minus_circle} alt='ckeck_icon' />
                                    </div>
                                    <div className="col-11">
                                        <p>Email support (48h SLA)</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="pricing-item shadow">
                            <div className="header">
                                <h4 className="text-center">Enterprise</h4>
                                <span className="price text-center">$259</span>
                                <Link to='/contact-us' className="btn btn-dark text-center">Order</Link>
                                <div className="small-desc-wrapper">
                                    <hr />
                                    <span className="small-desc">
                                        <span>25,000 </span> 
                                        Active network peers incl 
                                        <div className="tooltip">
                                            <img src={alarm_dark} alt='alarm_icon' />
                                            <span className="tooltiptext shadow">
                                                $0.029 (0 - 100,000)<br/>
                                                $0.024 (100,001 - 1,000,000)<br/>
                                                $0.019 (From 1,000,001)
                                            </span>
                                        </div>
                                    </span>
                                    <span className="small-desc">
                                        <span>3,000,000 </span> 
                                        Authorization Checks  
                                        <div className="tooltip">
                                            <img src={alarm_dark} alt='alarm_icon' />
                                            <span className="tooltiptext shadow">
                                                $0.04 / 1000 (0 - 10,000,000)<br/>
                                                0.03 / 1000 (From 10,000,001)
                                            </span>
                                        </div>  
                                    </span>
                                    <hr />
                                </div>
                            </div>
                            <div className="options">

                                <div className="row">
                                    <div className="col-1">
                                        <img src={check_circle} alt='ckeck_icon' />
                                    </div>
                                    <div className="col-11">
                                        <p>Identity and authentication</p>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-1">
                                            <img src={check_circle} alt='ckeck_icon' />
                                        </div>
                                        <div className="col-11">
                                            <p>Service integration SDKs</p>
                                        </div>
                                    </div>
                                <div className="row">
                                    <div className="col-1">
                                            <img src={check_circle} alt='ckeck_icon' />
                                        </div>
                                        <div className="col-11">
                                            <p>UI integration SDK</p>
                                        </div>
                                    </div>
                                <div className="row">
                                    <div className="col-1">
                                            <img src={check_circle} alt='ckeck_icon' />
                                        </div>
                                        <div className="col-11">
                                            <p>Audit</p>
                                        </div>
                                    </div>
                                <div className="row">
                                    <div className="col-1">
                                            <img src={check_circle} alt='ckeck_icon' />
                                        </div>
                                        <div className="col-11">
                                            <p>Authorization</p>
                                        </div>
                                    </div>
                                <div className="row">
                                    <div className="col-1">
                                        <img src={check_circle} alt='ckeck_icon' />
                                    </div>
                                    <div className="col-11">
                                        <p>Community forum</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-1">
                                        <img src={check_circle} alt='ckeck_icon' />
                                    </div>
                                    <div className="col-11">
                                        <p>Email support (48h SLA)</p>
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>





                </div>
            </div>

        </div>
      </div>
    </section>

    <section className="special-section">
      <div className="container">
        <div className="container-special-2">
            <div className="row">
            <div className="col-lg-10 offset-lg-1 col-12">
                <div className="row align-items-center">
                    <div className="col-lg-8 col-12 text-lg-start text-center">
                        <h2>Need something special?</h2>
                    </div>
                    <div className="col-lg-4 col-12 special-btn-wrapper text-lg-end text-center">
                        <Link to='/contact-us' className="btn btn-transparent"><img src={wemail} alt='email_icon' />Get a consultation</Link>
                    </div>
                </div>
            </div>
            </div>
        </div>
      </div>
    </section>

  </Layout>

  )
}

export const Head = () => <Seo title="Pricing" description="See pricing for iamcore - a turnkey solution for granular access control with state-of-the-art security." image={openGpaphImage}/>

export default Pricing
